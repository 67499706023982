import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { ContactFormButton } from "./ContactFormButton"

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SubmitButtonWrapper = styled.div`
  width: 440px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: 440px;
  }
`

type Props = {
}
export const ContactFormToTopButton: React.VFC<Props> = () => {
  return (
    <ButtonWrapper>
      <SubmitButtonWrapper>
        <ContactFormButton
          text={"TOPへ戻る"}
          type={"button"}
            onClick={e => {
              e.preventDefault()
              navigate("../../../", { replace: true })
            }}
        />
      </SubmitButtonWrapper>
    </ButtonWrapper>
  )
}