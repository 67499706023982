import React from "react"

/**
 * Material
 */
import { ServiceAccepted } from "../../../components/contact/service/accepted"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `CONTACT`, path: `contact` },
  { text: `製品・サービス`, path: `contact/service` },
]

/**
 * Component
 */
export default function Home() {
  return (
    <ServiceAccepted breadcrumbsData={breadcrumbsData} />
  )
}